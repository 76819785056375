import React from 'react'
import './openInfo.css'
import {GatsbyImage, StaticImage} from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import Row from 'react-bootstrap/Row'
import Card from 'react-bootstrap/Card'
import Col from 'react-bootstrap/Col'
import ListGroup from 'react-bootstrap/ListGroup'
import Container from 'react-bootstrap/Container'

export const OpenInfo = () => {
    const textsShadow = '0.03em 0 #0e1116, 0 0.04em #0e1116, -0.04em 0 #0e1116, 0 -0.04em #0e1116';
    return (
        <Container style={{ marginTop: '2rem', height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'space-between'}} >
            <Row>
                <Card style={{ backgroundColor: 'transparent', border: 'none', paddingLeft: '0px' }}>
                    <Card.Title>
                        <Container>
                            <h2 className="openHours">Öppettider</h2>
                        </Container>
                    </Card.Title>
                    <Card.Body className="open-info-text" style={{ padding: '0px'}} style={{fontWeight: '600'}}>
                        <ListGroup variant="flush">
                            <ListGroup.Item style={{ backgroundColor: 'transparent', display: 'flex', borderBottom: '1px solid #FFEDDF', color: '#FFEDDF', textShadow:textsShadow }}>
                                <Container>Måndag-Fredag</Container>
                                <Container>11:00-18:00</Container>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', display: 'flex', borderBottom: '1px solid #FFEDDF', color: '#FFEDDF', textShadow:textsShadow }}>
                                <Container>Lördag</Container>
                                <Container>11:00-16:00</Container>
                            </ListGroup.Item>
                            <ListGroup.Item style={{ backgroundColor: 'transparent', display: 'flex', borderBottom: '1px solid #FFEDDF', color: '#FFEDDF', textShadow:textsShadow }}>
                                <Container>Söndag</Container>
                                <Container>Stängt</Container>
                            </ListGroup.Item>
                        </ListGroup>
                    </Card.Body>
                </Card>
            </Row>
            <div style={{display: 'flex', justifyContent: 'center'}}>
                <div className="monteringshallenLogo">
                    <StaticImage src='../../../images/monteringshallen_logo.jpg' alt="Monteringshallen" width={52} height={70}/>
                </div>
                <div>
                    <h5>SICKLA KÖPKVARTER</h5>
                    <h5>SMEDJGEGATAN 10</h5>
                    <h5>131 54 NACKA</h5>
                </div>
            </div>
        </Container>
      )
}

import './about.css'
import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export const About = () => {
    return(
        <section className="section" id="about">
            <Container className="text-content-container">
                <Row>
                    <div className="text-content">
                        <div className="section-heading">
                            <h6>Om oss</h6>
                        </div>
                        <p
                            data-sal="slide-left"
                            data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                            data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                            data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                        >
                            <a className="info" rel="nofollow noopener" href="#employees" target="_parent">Philip Lannerholt</a> startade bolaget 2017, i april 2018 öppnade vi vår första butik/smedja i Monteringshallen i Sickla Köpkvarter. 
                        </p>
                        <p
                            data-sal="slide-right"
                            data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                            data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                            data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                        >
                            <br/>
                            Vi strävar efter <span className="bold-span">högsta kvalitet</span>.
                            Det flesta smyckena vi har i butiken är gjorda i vår egen smedja. 
                            Ett fåtal smycken är intagna från andra kvalitetsmärken.
                            Vi designar smycken tillsammans med kund, till exempel vigselringar,
                            förlovningsringar, student-, konfirmations- och doppresenter.
                            Vi gör även reparationer, omarbetningar av gamla smycken, gravyr och steninfattningar.
                            Det som präglar vårt arbete är alltid ett gott hantverk med hög kvalitet.
                            Hos oss får du något unikt efter just dina önskemål.
                        </p>
                        <p
                            data-sal="slide-left"
                            data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                            data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                            data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                        >
                            <br/>
                            <span className="bold-span">Hållbarhet</span> är viktigt för oss.
                            Vi jobbar bara med återvunnet guld som är det bästa ur en miljösynpunkt samt kimberleydiamanter, 
                            det vill säga konfliktfria diamanter. Förutom guld jobbar vi även med silver och platina. 
                            Ädelstenar och pärlor kan vi ta hem efter kundens önskemål.
                        </p>
                    </div>
                </Row>
                <Button href="#projects" variant="primary" className="margin-top-m ovalBtn">
                    Galleri <FontAwesomeIcon icon={faArrowRight}/>
                </Button>
            </Container>
        </section>
    )
}
import './header.css'
import React from 'react'
import { StaticImage } from "gatsby-plugin-image"

export const Header = () => {
    return(
        <header className="header-area header-sticky">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <nav className="main-nav">

                            <a href="index.html" className="logo">
                                <StaticImage className="logo-img" src='../../images/guldsmideByLannerholtLogo2.png' alt=""/>
                            </a>

                            <ul className="nav">
                                <li className="scroll-to-section"><a href="#top" className="active">Start</a></li>
                                <li className="scroll-to-section"><a href="#about">Om oss</a></li>
                                <li className="scroll-to-section"><a href="#projects">Galleri</a></li>
                                <li className="scroll-to-section"><a href="#contact-us">Kontakt</a></li> 
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </header>
    )
}

import './contact.css'
import React from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faPhone, faGlobe, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import emailjs from "emailjs-com"
export class Contact extends React.Component {
    constructor() {
        super();
        this.state = {
            emailSendingStatus: 'not-sent'
        };

        this.sendEmail = this.sendEmail.bind(this);
    }

    sendEmail(event) {
        event.preventDefault()
        this.setState({emailSendingStatus: 'sending'})
        emailjs
            .sendForm(
            process.env.EMAILJS_SERVICE_ID,
            process.env.EMAILJS_TEMPLATE_ID,
            event.target,
            process.env.EMAILJS_USER_ID
            )
            .then(
            result => {
                this.setState({emailSendingStatus: 'sent'})
            },
            error => {
                this.setState({emailSendingStatus: 'error'})
            }
        )
    }
    
    render() {
        return (
            <section className="section" id="contact-us">
                <Container>
                    <Row>
                        <Col 
                            lg="4" md="4" xs="12"
                            data-sal="slide-right"
                            data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                            data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                            data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                        >
                            <div className="left-text-content">
                                <div className="section-heading">
                                    <h2>Kontakta oss</h2>
                                </div>
                                <ul className="contact-info">
                                    <li className="contact-row"><FontAwesomeIcon icon={faPhone} className="info-icon" /> 08-615-1470</li>
                                    <li className="contact-row"><FontAwesomeIcon icon={faGlobe} className="info-icon"/> https://guldsmidebylannerholt.se/</li>
                                    <li className="contact-row"><FontAwesomeIcon icon={faEnvelope} className="info-icon"/>Philip@guldsmidebylannerholt.se</li>
                                </ul>
                            </div>
                        </Col>
                        <Col 
                            lg="8" md="8" xs="12"
                            data-sal="slide-left"
                            data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                            data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                            data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                        >
                            <div className="contact-form">
                                <Form id="contact" onSubmit={this.sendEmail}>
                                    <Row>
                                        <Col md="6" sm="12">
                                            <Form.Control name="name" type="text" id="name" placeholder="Namn *" required=""/>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <Form.Control name="phone" type="text" id="phone" placeholder="Telefonnummer" required=""/>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <Form.Control name="email" type="email" id="email" placeholder="Email *" required=""/>
                                        </Col>
                                        <Col md="6" sm="12">
                                            <Form.Control name="subject" type="text" id="subject" placeholder="Ämne"/>
                                        </Col>
                                        <Col lg="12">
                                            <Form.Control as="textarea" name="message" rows="6" id="message" placeholder="Meddelande" required=""/>
                                        </Col>
                                        <Col lg="12">
                                            <Button type="submit" variant="secondary" id="form-submit" className="ovalBtn">
                                            {
                                                this.state.emailSendingStatus == 'sending'
                                                ? (
                                                    <Container>
                                                        <Spinner
                                                            as="span"
                                                            animation="grow"
                                                            size="sm"
                                                            role="status"
                                                            aria-hidden="true"
                                                        />
                                                        <span>Skickar...</span>
                                                    </Container>
                                                )
                                                : (
                                                    <span>Skicka <FontAwesomeIcon icon={faArrowRight}/></span>
                                                )
                                            }
                                            </Button>
                                        </Col>
                                    </Row>
                                    {
                                        this.state.emailSendingStatus == 'sent'
                                        ? (
                                            <Row className="email-message">
                                                <h6>Skickat!</h6>
                                            </Row>
                                        )
                                        : null
                                    }

                                    {
                                        this.state.emailSendingStatus == 'error'
                                        ? (
                                            <Row className="email-message">
                                                <h6>Något gick fel, försök igen senare!</h6>
                                            </Row>
                                        )
                                        : null
                                    }

                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        )
    }
}                                       

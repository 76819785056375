import './footer.css'
import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFacebook, faInstagram, faGoogle } from '@fortawesome/free-brands-svg-icons'

export const Footer = () => {
    return(
        <footer>
            <Container>
                <Row>
                    <Col lg="6" xs="12">
                        <div className="left-text-content">
                            <p>Copyright &copy; 2021 Guldsmide by Lannerholt AB. 
                            
                            - Design: <a rel="nofollow noopener" href="https://www.linkedin.com/in/charlie-rydholm-013522116/">Charlie Rydholm</a></p>
                        </div>
                    </Col>
                    <Col lg="6" xs="12">
                        <div className="right-text-content">
                                <ul className="social-icons">
                                    <li><p>Följ oss</p></li>
                                    <li><a rel="nofollow" href="https://www.instagram.com/guldsmidebylannerholt/"><FontAwesomeIcon icon={faInstagram} className="social-media-link" /></a></li>
                                    <li><a rel="nofollow" href="https://www.facebook.com/guldsmidebylannerholt"><FontAwesomeIcon icon={faFacebook} className="social-media-link" /></a></li>
                                    <li><a rel="nofollow" href="https://www.google.com/maps/place/Guldsmide+by+Lannerholt/@59.3059055,18.1219427,19z/data=!4m5!3m4!1s0x465f79173257f267:0x42224e64edf5507!8m2!3d59.3059706!4d18.1220929"><FontAwesomeIcon icon={faGoogle} className="social-media-link" /></a></li>
                                </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </footer>
    )
}
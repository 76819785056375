
import './services.css'
import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import { StaticImage } from "gatsby-plugin-image"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

export const Services = () => {
    return(
    <section className="section" id="services">
        <Container>
            <Row>
                <Col 
                    lg="4" md="6" sm="12" xs="12" 
                    data-sal="slide-right"
                    data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                    data-sal-delay="100" // adds delay to the animation (from 5 to 1000 ms)
                    data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                >
                    <Container className="features-item">
                        <Row>
                            <StaticImage
                                src='../../images/gl1.jpg'
                                quality={95}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="Ring"
                                style={{ marginBottom: `0.5rem`, maxHeight: "100%" }}
                            />
                        </Row>
                        <Row>
                            <h4>Förlovningsringar</h4>
                        </Row>
                    </Container>
                </Col>
                <Col 
                    lg="4" md="6" sm="12" xs="12"
                    data-sal="slide-right"
                    data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                    data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                    data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                >
                    <Container className="features-item">
                        <Row>
                            <StaticImage
                                src='../../images/rsz_necklace.jpg'
                                quality={95}
                                formats={["AUTO"]}
                                alt="Ring"
                                style={{ marginBottom: `0.5rem`, maxHeight: "100%" }}
                            />
                        </Row>
                        <Row>
                            <h4>Halsband</h4>
                        </Row>
                    </Container>
                </Col>
                <Col 
                    lg="4" md="6" sm="12" xs="12" 
                    data-sal="slide-right"
                    data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                    data-sal-delay="200" // adds delay to the animation (from 5 to 1000 ms)
                    data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                >
                    <Container className="features-item">
                        <Row>
                            <StaticImage
                                src='../../images/wrench.jpg'
                                quality={95}
                                formats={["AUTO", "WEBP", "AVIF"]}
                                alt="Ring"
                                style={{ marginBottom: `0.5rem`, maxHeight: "100%" }}
                            />
                        </Row>
                        <Row>
                            <h4>Dekorationer</h4>
                        </Row>
                    </Container>
                </Col>
            </Row>
            <Button href="#contact-us" variant="secondary" className="margin-top-m ovalBtn">
                Kontakt <FontAwesomeIcon icon={faArrowRight}/>
            </Button>
        </Container>
    </section>
    )
}
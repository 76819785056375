import * as React from "react"
import { 
  Header,
  MainContent,
  About,
  Employees,
  Contact,
  References,
  Footer,
  Services,
  FindUs,
} from '../components'
import Seo from "../components/seo/seo"
import '../css/app.scss'
import { storeImg } from '../images/store.jpg';

const IndexPage = () => (
  <div>
    <Seo image={storeImg}></Seo>
    <Header></Header>
    <MainContent></MainContent>
    <Services></Services>
    <About></About>
    <Contact></Contact>
    <Employees></Employees>
    <References></References>
    <FindUs></FindUs>
    <Footer></Footer>
  </div>
    
)

export default IndexPage

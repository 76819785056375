import './mainContent.css'
import React, { Component } from 'react'
import logo1 from '../../images/logo1.jpg'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowDown } from '@fortawesome/free-solid-svg-icons'
import { StaticImage } from "gatsby-plugin-image"

export const MainContent = () =>{
    return(
        <div>
            <div className="main-banner header-text" id="top">
                <div className="main-area">
                    <div className="item">
                        <div className="img-fill">
                            <StaticImage className="header-img" src='../../images/logo1.jpg' alt=""/>

                            <div className="text-content">
                                <h3>Välkommen</h3>
                                <h5>Guld- & silversmide i Stockholm</h5>
                                <a href="#find-us" className="main-stroked-button">Hitta hit</a>
                                <a href="#contact-us" className="main-filled-button">Kontakt</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="scroll-down scroll-to-section"><a href="#about"><FontAwesomeIcon icon={faArrowDown}/></a></div>
        </div>
    )
}

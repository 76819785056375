import './employees.css'
import React, { Component } from 'react'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { StaticImage } from "gatsby-plugin-image"

export const Employees = () => {
    return(
        <section className="section" id="employees">
            <Container>
                <Row>
                    <Col lg="12">
                        <div className="section-heading">
                            <h2>Medarbetare</h2>
                        </div>
                    </Col>
                    <Col lg="12" md="12" sm="12" className="mobile-bottom-fix-big" data-scroll-reveal="enter left move 30px over 0.6s after 0.4s">
                        <div className="item author-item">
                            <div 
                                className="member-thumb"
                                data-sal="slide-left"
                                data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                                data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                                data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                            >
                                <StaticImage className="member-img" src='../../images/philip.jpg' alt=""/>
                            </div>
                            <div
                                data-sal="slide-right"
                                data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                                data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                                data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                            >
                                <h4>Philip Lannerholt</h4>
                                <span>Guld- & silversmed, Ägare</span>
                            </div>
                            <p
                                data-sal="slide-left"
                                data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                                data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                                data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                            >
                                Jag utbildade mig på Dackeskolan i Mjölby med inriktning guldsmide. 
                                Direkt efter skolan började jag som lärling hos Bo Sander där jag tog gesällbrev. 
                                Efter 2 år som lärling blev jag anställd och har nu arbetat som guldsmed sedan 2010.
                            </p>
                        </div>                          
                    </Col>
                </Row>
            </Container>
        </section>
    )
}

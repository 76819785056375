import React from 'react'
import './findUs.css'
import {GatsbyImage} from "gatsby-plugin-image"
import { StaticQuery, graphql } from "gatsby"
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Container from 'react-bootstrap/Container'
import { OpenInfo } from './openInfo/openInfo'

export const FindUs = () => {
    return (
      	<section id="find-us">
        	<Container>
         		<Row>
					<Col 
						className="open-info"
						lg={{span: "6", order: 'first'}} 
						md={{span: "6", order: 'first'}} 
						sm={{span: "12", order: 'last'}} 
						xs={{span: "12", order: 'last'}}
						data-sal="slide-right"
						data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
						data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
						data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
					>
						<OpenInfo></OpenInfo>
					</Col>
					<Col 
						lg="6" md="6" sm="12" xs="12"
						data-sal="slide-left"
						data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
						data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
						data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
					>
						<Container>
						<StaticQuery
							query={graphql`
							query StaticMapQuery {
								staticMap {
									childFile {
										childImageSharp {
											gatsbyImageData(layout: FIXED)
										}
									}
								}
							}`}
							render={data => (
								<GatsbyImage className="img-wrapper" image={data.staticMap.childFile.childImageSharp.gatsbyImageData} alt="alt"/>
							)}
						/>
						</Container>
					</Col>
          		</Row>
        	</Container>
      </section>
      )

}

import './references.css'
import React, { Component } from 'react'
import InstagramEmbed from 'react-instagram-embed';
import "react-image-gallery/styles/scss/image-gallery.scss";
import ImageGallery from 'react-image-gallery';
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'

export class References extends React.Component {
    constructor() {
        super();
        this.state = {
            images: []
        };
    }

    componentDidMount() {
        let images = [];
        const directory = require.context('../../../public/images/reference', false, /\.(png|jpe?g|svg)$/);
        directory.keys().map((item, index) => images.push(item.replace("./", "")));

        images = images.map(i => ({original: `./images/reference/${i}`, thumbnail: `./images/reference/${i}`}));
        this.setState({images: images})
    }
    
    render() {
        return (
            <section className="section" id="projects">
                <Container>
                    <Row>
                        <div className="section-heading">
                            <h2>Galleri</h2>
                        </div>
                    </Row>
                    <Row>
                    <p 
                        className="section-paragraph"
                        data-sal="slide-left"
                        data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                        data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                        data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)    
                    >
                        Här kan du se några av de smycken som gjort här i våran verkstad.
                        Få inspiration eller bara beskåda fint hantverk.

                        Om du har några frågor eller vill använda något av dessa bilder som referens,
                        tveka inte att <a className="section-anchor" rel="nofollow noopener" href="#contact" target="_parent">kontakta oss</a>
                    </p>
                    </Row>
                    <div className="section-image-gallery">
                        <Row
                            data-sal="slide-right"
                            data-sal-duration="1500" // changes duration of the animation (from 200 to 2000 ms)
                            data-sal-delay="150" // adds delay to the animation (from 5 to 1000 ms)
                            data-sal-easing="ease" // sets easing for the animation (see easings.net for reference)
                        >
                            <ImageGallery
                                items={this.state.images}
                                showPlayButton="false"
                            />
                        </Row>
                    </div>
                </Container>
            </section>
        )
    }
}
